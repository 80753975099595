.prodotop-logo {
  width: 80px;
  height: 80px;
  background-size: contain;
  margin: 5px 10px;
  border-radius: 5px;
  &.logo {
  background-image: url("../../public/images/logo.png");

  }
}

.layout-container {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
}

li.list-item svg:hover,
li.list-item span:hover {
  color: #ffc8c6;
}
.left-sidebar #sidebar {
  background: #fff;
  position: relative;
  height: calc(100vh - 155px);
  padding: 7px;
  overflow-y: auto;
  min-width: 65px;
  border-radius: 10px;
}

.vertical-nav {
  display: flex;
  flex-direction: column;
}

main.main-content {
  width: 100%;
}

#sidebarCollapse {
  position: absolute;
  top: 0.5rem;
  right: 1.3rem;
}
#sidebar {
  height: inherit;
  margin: 1rem 1.2rem;
  text-align: left;
}
#sidebar ul li a {
  margin-left: 0.5rem;
  color: #87827a;
}
#sidebar .list-item {
  margin: 2rem 0;
  font-size: 15px;
  font-weight: 600;
}
#sidebar li a {
  text-decoration: none;
  padding-left: 0.5rem;
}
.icon-color {
  color: #738a9b;
}
.btn-custom {
  background-color: #c4b9b9;
  border-radius: 50%;
  color: #215cad;
  font-weight: 600;
}
.sidebar-header {
  margin-top: 4rem;
  text-align: center;
  color: #e9e9ea;
}
.usr-image {
  border: 2px solid white;
}
.collapse {
  display: none;
}
#sidebar.active .sidebar-header a {
  display: none;
}
#sidebar ul li a {
  text-align: left;
}
#sidebar.active ul li span {
  display: none;
}
#sidebar.active ul li i {
  display: block;
}
#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
.hidden {
  display: none;
}

@media (max-width: 768px) {
  #sidebar.active {
    width: 50px;
    text-align: center;
    margin: 0;
  }
  #sidebar {
    margin: 1rem;
  }
  #sidebarCollapse {
    right: 0.5rem;
  }
  #sidebar.active .sidebar-header a {
    display: none;
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
}
