:root {
  --pt-purple: #afaae3;
  --pt-ligth-purple: #f2f2fb;

  --pt-yellow: #fadc07;
  --pt-ligth-yellow: #faf6dc;

  --pt-green: #78e08f;
  --pt-ligth-green: #e9f5b5cc;

  --pt-blue: #a8c1ec;
  --pt-ligth-blue: #e7effc;

  --pt-brown: #9e522f;
  --pt-ligth-brown: #f0e5e1;

  
  --pt-white: #ffffff;
  --pt-gray: #cecece;
  --pt-light-gray: #f2f2f2;
  --pt-dark-gray: #333;
  --pt-dark-blue: #000c36;

  --pt-dark-pink: #e4909b;
  --pt-pink: #ffb0d5;
  --pt-ligth-pink: #f7d6e0;
  --pt-dark-green: #94b909;


  --pt-btn-1: #99c1b9;
  --pt-btn-2: #e6e18f;
  --pt-btn-3: #f7d6e0;
  --pt-btn-4: #a0d2db;
  --pt-btn-5: #afaae3;
}



// Principal-theme purple
body.purple {

  .btn-outline-primary {
    --bs-btn-color: var(--pt-purple);
    --bs-btn-border-color: var(--pt-purple);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--pt-purple);
    --bs-btn-hover-border-color: var(--pt-purple);
    --bs-btn-focus-shadow-rgb: 203, 12, 159;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--pt-purple);
    --bs-btn-active-border-color: var(--pt-purple);
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: var(--pt-purple);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--pt-purple);
    --bs-gradient: none;
  }




  .bg-primary{
    background: var(--pt-purple) !important;
    color: var(--pt-white) !important;
  }
  .bg-ligth-primary{
    background: var(--pt-ligth-purple);
  }
  background: #f1f1fb;

  .discussion-section {
    background: #f1f1fbba;
  }

  .form-switch .theme-color.form-check-input:checked {
    border-color: var(--pt-purple) !important;
    background-color: var(--pt-purple) !important;
  }

  .btn-color{
    &.btn-color-color-action {
      color: var(--pt-dark-gray);
      border: 2px solid var(--pt-purple) !important;
    }
    svg {
      color: var(--pt-purple) !important;
    }
  }

  .primary-color{
    color: var(--pt-purple) !important;
  }
  .secondary-color{
    color: var(--pt-ligth-purple) !important;
  }

  .chart-icon.productCardView {
    border-color: var(--pt-purple);
    color: var(--pt-purple);
    &.active {
      background: var(--pt-purple);
      border: 1px solid var(--pt-purple);
    }
  }

  .brush{
    color: white;
    border: none;
    background-color: var(--pt-purple)
  }

  .rbc-header {
    background: var(--pt-ligth-purple);
  }

  table.table.align-items-center tbody tr:nth-child(odd) {
    background: var(--pt-ligth-purple);
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    background: var(--pt-ligth-purple) !important;
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    .bg-icon-custom {
      background: var(--pt-purple);
    }
  }
  
  .order-steps, .team-accepted-steps {
    li.active span {
      background: var(--pt-purple);
      &:before {
        border-left-color: var(--pt-purple);
      }
    }
  }
  
  .purple-color {
    color: var(--pt-purple);
  }
  .purple-bg {
    background: var(--pt-purple);
  }
  
  .bg-gradient-dark-inverse,
  .bg-gradient-dark {
    background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
  }
  li.list-item.active {
    background: var(--pt-purple);
  }
  li.list-item.visited {
    a {
      color: var(--pt-purple) !important;
    }
  }



  .date-status {
    .progress-bar {
      background-color: var(--pt-purple) !important;
    }
  }
  .profitability {
    .progress-bar {
      background-color: var(--pt-purple);
    }
  }
  
  .calendar-step.active {
    background: var(--pt-purple);
    border: 1px solid var(--pt-purple);
  }
  
  .text-brief {
    color: var(--pt-purple);
  }
  .text-ticket {
    color: var(--pt-purple);
  }
  
  .nav-link.active .badge {
    background-image: linear-gradient(
      154deg,
      var(--pt-purple) 70%,
      var(--pt-purple) 100%
    );
  }
  
  .img-post {
    background-color: var(--pt-purple);
  }
  .calendar-add-todo {
    background: var(--pt-purple);
  }
  
  span.product-title.text-secondary {
    color: var(--pt-purple) !important;
  }
  
  .active-tab {
    background: var(--pt-purple);
  }
  
  .purple-link {
    color: var(--pt-purple);
    text-decoration: underline;
  }
  
  .btn-primary {
    background: var(--pt-purple);
  }
  .bg-primary {
    background: var(--pt-purple);
  }
  
  .task-container {
    span.box-task {
      &.todo-task {
        background: var(--pt-purple);
      }
    }
  }
  
  .FLHB {
    background-color: var(--pt-purple) !important;
  }
  .todo-FLHB {
    border-left: 15px solid var(--pt-purple) !important;
  }
}

body.yellow {
  .btn-outline-primary {
    --bs-btn-color: var(--pt-yellow);
    --bs-btn-border-color: var(--pt-yellow);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--pt-yellow);
    --bs-btn-hover-border-color: var(--pt-yellow);
    --bs-btn-focus-shadow-rgb: 203, 12, 159;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--pt-yellow);
    --bs-btn-active-border-color: var(--pt-yellow);
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: var(--pt-yellow);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--pt-yellow);
    --bs-gradient: none;
  }

  .bg-primary{
    background: var(--pt-yellow) !important;
    color: var(--pt-dark-gray) !important;
  }
  .bg-ligth-primary{
    background: var(--pt-ligth-yellow);
  }
  background: #f0f1f2;

  .discussion-section {
    background: #fadc0512;
  }

  .chart-icon.productCardView {
    border-color: var(--pt-yellow);
    color: var(--pt-yellow);
    &.active {
      background: var(--pt-yellow);
      border: 1px solid var(--pt-yellow);
    }
  }

  .form-switch .theme-color.form-check-input:checked {
    border-color: var(--pt-yellow) !important;
    background-color: var(--pt-yellow) !important;
  }

  .btn-color{
    &.btn-color-color-action {
      color: var(--pt-dark-gray);
      border: 2px solid var(--pt-dark-gray) !important;
    }
    svg {
      color: var(--pt-yellow) !important;
    }
  }

  .primary-color{
    color: var(--pt-yellow) !important;
  }
  .secondary-color{
    color: var(--pt-ligth-yellow) !important;
  }

  .brush{
    color: white;
    border: none;
    background-color: var(--pt-yellow);
  }

  .rbc-header {
    background: var(--pt-ligth-yellow);
  }

  table.table.align-items-center tbody tr:nth-child(odd) {
    background: var(--pt-ligth-yellow);
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    background: var(--pt-ligth-yellow) !important;
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    .bg-icon-custom {
      background: var(--pt-yellow);
      color: var(--pt-dark-gray) !important;

    }
  }
  
  .order-steps, .team-accepted-steps {
    li.active span {
      background: var(--pt-yellow);
      &:before {
        border-left-color: var(--pt-yellow);
      }
    }
  }
  
  .yellow-color {
    color: var(--pt-yellow);
  }
  .yellow-bg {
    background: var(--pt-yellow);
  }
  
  .bg-gradient-dark-inverse,
  .bg-gradient-dark {
    background: linear-gradient(315deg, var(--pt-yellow) 0%, var(--pt-yellow) 100%);
  }
  li.list-item.active {
    background: var(--pt-yellow);
  }
  .date-status {
    .progress-bar {
      background-color: var(--pt-yellow) !important;
    }
  }
  .profitability {
    .progress-bar {
      background-color: var(--pt-yellow);
    }
  }
  
  .calendar-step.active {
    background: var(--pt-yellow);
    border: 1px solid var(--pt-yellow);
  }
  
  .text-brief {
    color: var(--pt-yellow);
  }
  .text-ticket {
    color: var(--pt-yellow);
  }
  
  .nav-link.active .badge {
    background-image: linear-gradient(
      154deg,
      var(--pt-yellow) 70%,
      var(--pt-yellow) 100%
    );
  }
  
  .img-post {
    background-color: var(--pt-yellow);
  }
  .calendar-add-todo {
    background: var(--pt-yellow);
  }
  
  span.product-title.text-secondary {
    color: var(--pt-yellow) !important;
  }
  
  .active-tab {
    background: var(--pt-yellow);
  }
  
  .yellow-link {
    color: var(--pt-yellow);
    text-decoration: underline;
  }
  
  .btn-primary {
    background: var(--pt-yellow);
  }
  .bg-yellow {
    background: var(--pt-yellow);
  }
  
  .task-container {
    span.box-task {
      &.todo-task {
        background: var(--pt-yellow);
      }
    }
  }
  
  .FLHB {
    background-color: var(--pt-yellow) !important;
  }
  .todo-FLHB {
    border-left: 15px solid var(--pt-yellow) !important;
  }
}

body.green {
  .btn-outline-primary {
    --bs-btn-color: var(--pt-green);
    --bs-btn-border-color: var(--pt-green);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--pt-green);
    --bs-btn-hover-border-color: var(--pt-green);
    --bs-btn-focus-shadow-rgb: 203, 12, 159;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--pt-green);
    --bs-btn-active-border-color: var(--pt-green);
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: var(--pt-green);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--pt-green);
    --bs-gradient: none;
  }

  .bg-primary{
    background: var(--pt-green) !important;
    color: var(--pt-white) !important;
  }
  .bg-ligth-primary{
    background: var(--pt-ligth-green);
  }
  background: #f0f1f2;

  .discussion-section {
    background: #78e08f17;
  }

  .chart-icon.productCardView {
    border-color: var(--pt-green);
    color: var(--pt-green);
    &.active {
      background: var(--pt-green);
      border: 1px solid var(--pt-green);
    }
  }

  .primary-color{
    color: var(--pt-green) !important;
  }
  .secondary-color{
    color: var(--pt-ligth-green) !important;
  }

  .form-switch .theme-color.form-check-input:checked {
    border-color: var(--pt-green) !important;
    background-color: var(--pt-green) !important;
  }

  .btn-color{
    &.btn-color-color-action {
      color: var(--pt-dark-gray);
      border: 2px solid var(--pt-dark-gray) !important;
    }
    svg {
      color: var(--pt-green) !important;
    }
  }

  .brush{
    color: white;
    border: none;
    background-color: var(--pt-green);
  }

  .rbc-header {
    background: var(--pt-ligth-green);
  }

  table.table.align-items-center tbody tr:nth-child(odd) {
    background: var(--pt-ligth-green);
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    background: var(--pt-ligth-green) !important;
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    .bg-icon-custom {
      background: var(--pt-green);
    }
  }
  
  .order-steps, .team-accepted-steps {
    li.active span {
      background: var(--pt-green);
      &:before {
        border-left-color: var(--pt-green);
      }
    }
  }
  
  .green-color {
    color: var(--pt-green);
  }
  .green-bg {
    background: var(--pt-green);
  }
  
  .bg-gradient-dark-inverse,
  .bg-gradient-dark {
    background: linear-gradient(315deg, var(--pt-green) 0%, var(--pt-green) 100%);
  }
  li.list-item.active {
    background: var(--pt-green);
  }
  .date-status {
    .progress-bar {
      background-color: var(--pt-green) !important;
    }
  }
  .profitability {
    .progress-bar {
      background-color: var(--pt-green);
    }
  }
  
  .calendar-step.active {
    background: var(--pt-green);
    border: 1px solid var(--pt-green);
  }
  
  .text-brief {
    color: var(--pt-green);
  }
  .text-ticket {
    color: var(--pt-green);
  }
  
  .nav-link.active .badge {
    background-image: linear-gradient(
      154deg,
      var(--pt-green) 70%,
      var(--pt-green) 100%
    );
  }
  
  .img-post {
    background-color: var(--pt-green);
  }
  .calendar-add-todo {
    background: var(--pt-green);
  }
  
  span.product-title.text-secondary {
    color: var(--pt-green) !important;
  }
  
  .active-tab {
    background: var(--pt-green);
  }
  
  .green-link {
    color: var(--pt-green);
    text-decoration: underline;
  }
  
  .btn-primary {
    background: var(--pt-green);
  }
  .bg-green {
    background: var(--pt-green);
  }
  
  .task-container {
    span.box-task {
      &.todo-task {
        background: var(--pt-green);
      }
    }
  }
  
  .FLHB {
    background-color: var(--pt-green) !important;
  }
  .todo-FLHB {
    border-left: 15px solid var(--pt-green) !important;
  }
}

body.blue {
  .btn-outline-primary {
    --bs-btn-color: var(--pt-blue);
    --bs-btn-border-color: var(--pt-blue);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--pt-blue);
    --bs-btn-hover-border-color: var(--pt-blue);
    --bs-btn-focus-shadow-rgb: 203, 12, 159;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--pt-blue);
    --bs-btn-active-border-color: var(--pt-blue);
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: var(--pt-blue);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--pt-blue);
    --bs-gradient: none;
  }
  .bg-primary{
    background: var(--pt-blue) !important;
    color: var(--pt-white) !important;
  }
  .bg-ligth-primary{
    background: var(--pt-ligth-blue);
  }
  background: #f0f1f2;
  .discussion-section {
    background: #a8c1ed17;
  }

  .chart-icon.productCardView {
    border-color: var(--pt-blue);
    color: var(--pt-blue);
    &.active {
      background: var(--pt-blue);
      border: 1px solid var(--pt-blue);
    }
  }

  .primary-color{
    color: var(--pt-blue) !important;
  }
  .secondary-color{
    color: var(--pt-ligth-blue) !important;
  }

  .form-switch .theme-color.form-check-input:checked {
    border-color: var(--pt-blue) !important;
    background-color: var(--pt-blue) !important;
  }

  .btn-color{
    &.btn-color-color-action {
      color: var(--pt-dark-gray);
      border: 2px solid var(--pt-dark-gray) !important;
    }
    svg {
      color: var(--pt-blue) !important;
    }
  }

  .brush{
    color: white;
    border: none;
    background-color: var(--pt-blue);
  }

  .rbc-header {
    background: var(--pt-ligth-blue);
  }

  table.table.align-items-center tbody tr:nth-child(odd) {
    background: var(--pt-ligth-blue);
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    background: var(--pt-ligth-blue) !important;
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    .bg-icon-custom {
      background: var(--pt-blue);
    }
  }
  
  .order-steps, .team-accepted-steps {
    li.active span {
      background: var(--pt-blue);
      &:before {
        border-left-color: var(--pt-blue);
      }
    }
  }
  
  .blue-color {
    color: var(--pt-blue);
  }
  .blue-bg {
    background: var(--pt-blue);
  }
  
  .bg-gradient-dark-inverse,
  .bg-gradient-dark {
    background: linear-gradient(315deg, var(--pt-blue) 0%, var(--pt-blue) 100%);
  }
  li.list-item.active {
    background: var(--pt-blue);
  }
  .date-status {
    .progress-bar {
      background-color: var(--pt-blue) !important;
    }
  }
  .profitability {
    .progress-bar {
      background-color: var(--pt-blue);
    }
  }
  
  .calendar-step.active {
    background: var(--pt-blue);
    border: 1px solid var(--pt-blue);
  }
  
  .text-brief {
    color: var(--pt-blue);
  }
  .text-ticket {
    color: var(--pt-blue);
  }
  
  .nav-link.active .badge {
    background-image: linear-gradient(
      154deg,
      var(--pt-blue) 70%,
      var(--pt-blue) 100%
    );
  }
  
  .img-post {
    background-color: var(--pt-blue);
  }
  .calendar-add-todo {
    background: var(--pt-blue);
  }
  
  span.product-title.text-secondary {
    color: var(--pt-blue) !important;
  }
  
  .active-tab {
    background: var(--pt-blue);
  }
  
  .blue-link {
    color: var(--pt-blue);
    text-decoration: underline;
  }
  
  .btn-primary {
    background: var(--pt-blue);
  }
  .bg-blue {
    background: var(--pt-blue);
  }
  
  .task-container {
    span.box-task {
      &.todo-task {
        background: var(--pt-blue);
      }
    }
  }
  
  .FLHB {
    background-color: var(--pt-blue) !important;
  }
  .todo-FLHB {
    border-left: 15px solid var(--pt-blue) !important;
  }
}

body.brown {

  .btn-outline-primary {
    --bs-btn-color: var(--pt-brown);
    --bs-btn-border-color: var(--pt-brown);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--pt-brown);
    --bs-btn-hover-border-color: var(--pt-brown);
    --bs-btn-focus-shadow-rgb: 203, 12, 159;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--pt-brown);
    --bs-btn-active-border-color: var(--pt-brown);
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: var(--pt-brown);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--pt-brown);
    --bs-gradient: none;
  }

  .bg-primary{
    background: var(--pt-brown) !important;
    color: var(--pt-white) !important;
  }
  .bg-ligth-primary{
    background: var(--pt-ligth-brown);
  }
  background: #f0f1f2;

  .discussion-section {
    background: #9e522f12;
  }

  .chart-icon.productCardView {
    border-color: var(--pt-brown);
    color: var(--pt-brown);
    &.active {
      background: var(--pt-brown);
      border: 1px solid var(--pt-brown);
    }
  }

  .primary-color{
    color: var(--pt-brown) !important;
  }
  .secondary-color{
    color: var(--pt-ligth-brown) !important;
  }

  .form-switch .theme-color.form-check-input:checked {
    border-color: var(--pt-brown) !important;
    background-color: var(--pt-brown) !important;
  }

  .btn-color{
    &.btn-color-color-action {
      color: var(--pt-dark-gray);
      border: 2px solid var(--pt-dark-gray) !important;
    }
    svg {
      color: var(--pt-brown) !important;
    }
  }

  .brush{
    color: white;
    border: none;
    background-color: var(--pt-brown);
  }

  .rbc-header {
    background: var(--pt-ligth-brown);
  }

  table.table.align-items-center tbody tr:nth-child(odd) {
    background: var(--pt-ligth-brown);
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    background: var(--pt-ligth-brown) !important;
  }
  
  .card.card-produced,
  .card.card-new_pending,
  .card.card-new_1,
  .card.card-new_2,
  .card.card-new_3,
  .card.card-new_4,
  .card.card-new_5,
  .card.card-in_progress {
    .bg-icon-custom {
      background: var(--pt-brown);
    }
  }
  
  .order-steps, .team-accepted-steps {
    li.active span {
      background: var(--pt-brown);
      &:before {
        border-left-color: var(--pt-brown);
      }
    }
  }
  
  .brown-color {
    color: var(--pt-brown);
  }
  .brown-bg {
    background: var(--pt-brown);
  }
  
  .bg-gradient-dark-inverse,
  .bg-gradient-dark {
    background: linear-gradient(315deg, var(--pt-brown) 0%, var(--pt-brown) 100%);
  }
  li.list-item.active {
    background: var(--pt-brown);
  }
  .date-status {
    .progress-bar {
      background-color: var(--pt-brown) !important;
    }
  }
  .profitability {
    .progress-bar {
      background-color: var(--pt-brown);
    }
  }
  
  .calendar-step.active {
    background: var(--pt-brown);
    border: 1px solid var(--pt-brown);
  }
  
  .text-brief {
    color: var(--pt-brown);
  }
  .text-ticket {
    color: var(--pt-brown);
  }
  
  .nav-link.active .badge {
    background-image: linear-gradient(
      154deg,
      var(--pt-brown) 70%,
      var(--pt-brown) 100%
    );
  }
  
  .img-post {
    background-color: var(--pt-brown);
  }
  .calendar-add-todo {
    background: var(--pt-brown);
  }
  
  span.product-title.text-secondary {
    color: var(--pt-brown) !important;
  }
  
  .active-tab {
    background: var(--pt-brown);
  }
  
  .brown-link {
    color: var(--pt-brown);
    text-decoration: underline;
  }
  
  .btn-primary {
    background: var(--pt-brown);
  }
  .bg-brown {
    background: var(--pt-brown);
  }
  
  .task-container {
    span.box-task {
      &.todo-task {
        background: var(--pt-brown);
      }
    }
  }
  
  .FLHB {
    background-color: var(--pt-brown) !important;
  }
  .todo-FLHB {
    border-left: 15px solid var(--pt-brown) !important;
  }
}

// -------------------  background #ffffff

.bottom-documents {
  background: var(--pt-white);
}

.img-post span {
  background: var(--pt-white);
}

nav.breadcrumb {
  background: var(--pt-white);
}
.bg-icon-nil {
  background-color: var(--pt-white);
}
.rbc-toolbar {
  background: var(--pt-white);
}
.rbc-day-bg.rbc-today {
  background: var(--pt-white);
}
.rbc-time-gutter.rbc-time-column {
  background: var(--pt-white);
}
span.rbc-btn-group button {
  background: var(--pt-white);
}
.calendar-step {
  background: var(--pt-white);
}
span.rbc-btn-group button {
  background: var(--pt-white);
}

.calendar-step {
  background: var(--pt-white);
}

.expanded-modal {
  background: var(--pt-white);
}

// ----------------------  color #ffffff

.chart-icon.productCardView {
  &.active {
    color: var(--pt-white) !important;
  }
}

.date-status {
  .progress-bar,
  .progress {
    color: var(--pt-white);
  }
}
.profitability {
  .progress-bar,
  .progress {
    color: var(--pt-white);
  }

  .status-progress.danger {
    .progress-bar {
      color: var(--pt-white);
    }
  }
}
span.white-text {
  color: var(--pt-white) !important;
}

.card.card-produced,
.card.card-new_pending,
.card.card-new,
.card.card-in_progress,
.card.card-completed {
  .bg-icon-custom {
    i {
      color: var(--pt-white) !important;
    }
  }
}


.bg-gradient-dark-inverse,
.bg-gradient-dark {
  color: var(--pt-white);
}
.notification-active {
  color: var(--pt-white) !important;
}
.myCustomHeight {
  .rbc-event.custom-event:after,  .rbc-event-content:after {
    color: var(--pt-white);
  }
}
.nav-link.active .badge {
  color: var(--pt-white) !important;
}
.chart-icon {
  color: var(--pt-white);
}

.expanded-modal {
  .card-header h6 {
    color: var(--pt-white);
  }
}

li.list-item.active a {
  color: var(--pt-white) !important;
}
.btn.copy:active {
  color: var(--pt-white) !important;
}
li.list-item.active {
  color: var(--pt-white);
}

.calendar-add-todo {
  color: var(--pt-white);
}
.accordion-toggle {
  span.badge-accordion {
    color: var(--pt-white);
  }
}
.calendar-step.active {
  color: var(--pt-white);
}

.active-tab a {
  color: var(--pt-white);
}

// -----------------------  border #ffffff

.rbc-time-view {
  border-color: var(--pt-white);
}

.rbc-event.custom-event {
  border-color: var(--pt-white) !important;
}

// -----------------------  #f2f2f2

.note-card {
  background: var(--pt-light-gray);
}
.card-order {
  .card-header {
    background: var(--pt-light-gray);
  }
}
.product-card {
  background: var(--pt-light-gray);
}
.wave {
  background-color: var(--pt-light-gray);
}

.valid-emails-list ul li {
  background: var(--pt-light-gray);
}

// ----------------------- #cecece

.card.card-produced,
.card.card-new_pending,
.card.card-new_1,
.card.card-new_2,
.card.card-new_3,
.card.card-new_4,
.card.card-new_5,
.card.card-in_progress, .card-progress_pending {

  .bg-icon-nil {
    i {
      color: var(--pt-gray) !important;
    }
  }
}

.order-steps, .team-accepted-steps {
  li span {
    background: var(--pt-gray);
  }

  li span:before {
    border-left: 30px solid var(--pt-gray);
  }
}
.card-completed .icon {
  background: var(--pt-gray);
}
.card-order {
  .card-header {
    border-bottom: 1px solid var(--pt-gray);
  }
}




// ----------------------- #333

.order-steps, .team-accepted-steps {
  li span {
    color: var(--pt-dark-gray);
  }
}

.myCustomHeight {
  .rbc-event.custom-event[title]:after, .rbc-event-content[title]:after {
    background-color: var(--pt-dark-gray);
  }
  .rbc-event.custom-event.done-event[title]:after {
    background-color: var(--pt-dark-gray);
  }
}

.broun-text {
  color: var(--pt-dark-gray);
}
.navbar-toggler .navbar-toggler-icon .navbar-toggler-bar {
  background: var(--pt-dark-gray) !important;
}
.rbc-event.custom-event {
  color: var(--pt-dark-gray) !important;
}

.rbc-event.custom-event {
  &.done-event {
    color: var(--pt-dark-gray) !important;
  }
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  h6 span {
    color: #344767 !important;
  }
}

.prodotop-link {
  border: 1px solid #87827b;
  background: var(--pt-light-gray);
  color: #4f4a42;
}
.calendar-step {
  border: 1px solid #6a778f;
  color: #393c3e;
}
.accordion-toggle {
  span.badge-accordion {
    background: #67748e;
  }
}

.btn.copy:active {
  background: #68758e !important;
}

.btn-start {
  color: var(--pt-btn-1);
  border: 2px solid var(--pt-btn-1) !important;
}
.btn-stop {
  color: var(--pt-dark-pink);
  border: 2px solid var(--pt-dark-pink) !important;
}
.btn-cut {
  color: var(--pt-btn-4);
  border: 2px solid var(--pt-btn-4) !important;
}


// ----------------- Purple

// .done-todo {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }

// .quality-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }
// .ticket-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }
// .todo-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }
// .insight-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }
// .delete-todo {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }
// .ce-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }
// .document-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }

// .email-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }

// .undrag-todo {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }

// .note-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }

// .info-color {
//   background: linear-gradient(315deg, var(--pt-purple) 0%, var(--pt-purple) 100%);
// }

// ----- dark

.rbc-header {
  color: var(--pt-dark-blue);
}

span.rbc-label {
  color: var(--pt-dark-blue);
}

span.rbc-toolbar-label {
  color: var(--pt-dark-blue);
}

span.rbc-toolbar-label {
  color: var(--pt-dark-blue);
}

.card-order h6 span {
  color: var(--pt-dark-blue) !important;
}

.notification-active {
  background: var(--pt-dark-blue);
}



.calendar-step {
  border: 1px solid var(--pt-dark-blue);
  color: var(--pt-dark-blue);
}

.brief-color,
.duplicate-tod {
  color: var(--pt-dark-blue);
}

// ------------------ blue

.rbc-event.custom-event {
  background-color: var(--pt-ligth-blue);
}

.loading-effect.blur {
  .rbc-calendar {
    background: var(--pt-ligth-blue);
  }
}

.order-date-end-filter {
  background: var(--pt-ligth-blue) !important;
}


// ----------- rose

.order-date-end-filter {
  &.active {
    background: var(--pt-ligth-pink) !important;
  }
}

.rbc-header {
  &.rbc-today {
    background: var(--pt-ligth-pink) !important;
  }
}


.rbc-event.custom-event {
  &.FLDY {
    background-color: var(--pt-ligth-pink) !important;
  }
}

//-------------------------

.card.card-progress_pending,
.card.card-new_pending {
  .bg-icon-custom {
    background: var(--pt-pink);
  }
}

.FLDY {
  background-color: var(--pt-pink) !important;
}

.todo-FLDY {
  border-left: 15px solid var(--pt-pink) !important;
}

.has-notification {
  color: var(--pt-pink) !important;
}

// ----------------------

.rbc-event.custom-event {
  &.FLDY {
    border-left: 2px solid var(--pt-dark-pink) !important;
  }
}

.FLZR {
  background-color: var(--pt-dark-pink) !important;
}

.todo-FLZR {
  border-left: 15px solid var(--pt-dark-pink) !important;
}

.profitability {
  .status-progress.danger {
    .progress-bar {
      background-color: var(--pt-dark-pink) !important;
    }
  }
}

// ----------------------

.programmed-border {
  border-color: var(--pt-brown) !important;
}

.text-programmed {
  color: var(--pt-brown);
}

span.reset {
  color: var(--pt-brown);
}

td.close-date small {
  color: var(--pt-brown);
}

.retard-filter-item {
  background: var(--pt-ligth-brown);
}

.programmed-border {
  background: var(--pt-ligth-brown);
}

// --------- Green

.text-validated {
  color: var(--pt-dark-green) !important;
}

.validated-border {
  border-color: var(--pt-dark-green) !important;
}

.rbc-event.custom-event {
  &.done-event, &.done-event.FLZR {
    border-left: 2px solid var(--pt-dark-green) !important;
  }
}

.rbc-event.custom-event {
  &.done-event, &.done-event.FLZR {
    background: var(--pt-ligth-green) !important;
  }
}

.img-post {
  &.validated {
    background-color: var(--pt-ligth-green);
  }
}

.validated-border {
  background: var(--pt-ligth-green);
}

// ------------------- chiata

.positive{
  color: var(--pt-dark-green);
}
.negative{
  color: var(--pt-dark-pink)
}


.text-quality {
  color: #74e1b8;
}
.task-container {
  span.box-task {
    &.done-task {
      background: #78e08f;
    }
  }
}

.purple-border {
  border-color: #1c21383e !important;
  border: 0px solid;
  border-radius: 14px !important;
}

.modal-bg {
  background: #3333337b;
}

.card.dashboard-card {
  background: #f2f2f2e7;
}

.ia-loader {
  background: #3333337b;
}

.expanded-modal {
  box-shadow: 0 20px 27px 0 rgba(0, 0, 0, 0.05);
}

.rbc-today {
  background: #f9e7f36b;
}

.rbc-event.custom-event {
  &.FLHB {
    background-color: #c3b2fca3 !important;
    border-left: 2px solid var(--pt-purple) !important;
  }
}

.rbc-event.custom-event {
  &.FLZR {
    background-color: #e4909ba3 !important;
    border-left: 2px solid #b8747d !important;
  }
}

// calendar

// -----------  Multi color
