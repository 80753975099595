.flex-middle {
  display: flex;
  justify-content: center;
  gap: 15px;
}
button.unherit-btn {
  border: none;
  background: none;
}

.card.medium-card {
  min-height: 450px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
}

.form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.form-container label {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-container input[type="text"] {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.form-container button[type="submit"],
.form-container button[type="button"] {
  padding: 0.5rem;
  margin: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.form-container button[type="submit"]:hover,
.form-container button[type="button"]:hover {
  background-color: #0069d9;
}

.alert {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
}

.alert-success {
  background-color: #28a745;
}

.alert-danger {
  background-color: #dc3545;
}

// custom provisoir

// table {
//   width: 100%;
//   margin: 10px 0;
//   th {
//     background-color: #cdf3f3;
//   }
//   td,
//   th {
//     border: 1px solid;
//     padding: 5px;
//   }
// }

.link-action,
.btn-action {
  background: #dce775;
  padding: 5px !important;
  border-radius: 3px;
  margin: 0 5px;
  text-decoration: none !important;
  color: #333;
  border: none !important;

  &.back {
    background: #cecece;
  }
  &.add {
    background: #90caf9;
  }
  &.update {
    background: #ffd54f;
  }
  &.delete {
    background: #ff8a65;
  }
  &.utils {
    background: #43bebe;
  }
  &.utils-1 {
    background: #468f58;
  }
}

.title-link {
  text-decoration: none !important;
  color: #118f8f;
  font-size: 18px;
  font-weight: 500;
}
.flex {
  display: flex;
  padding: 5px;
}

.flex a,
.flex button {
  width: 100%;
  text-align: center;
  &.left {
    text-align: left !important;
  }
  &.right {
    text-align: right !important;
  }
}
