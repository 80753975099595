@import "forms.scss";
@import "nucleo-icons.css";
@import "nucleo-svg.css";
@import "soft-ui-dashboard.css";
@import "sidebar.scss";
@import "colors.scss";

.bottom {
  position: absolute;
  bottom: 0;
}
.panel{
  padding: 25px;
  border-radius: 15px;
  &.error{
    background-color: #f8cbcb;
  }
}
.str-video {
  background-color: #272a30;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}

.separator-1 {
  height: 1px;
  background: #f3f3f3;
  margin: 10px 0;
}

.task-input {
  display: grid;
  grid-template-columns: 1fr auto;
}

.reports-card{
  overflow-x: auto;
}

.doc-sent {
  border-left: 5px solid;
}

.form-check:hover {
  background: #f2f2f2;
}

.product-name {
  font-size: 14px;
  font-weight: 600;
}

.loading-reports {
  height: 300px;
  margin: auto;
  margin-top: 250px;
}

.loading-reports span {
  font-size: 45px;
  margin-top: 20px;
  margin-left: 15px;
}

.tags-list ul {
  list-style: none;
}

button.kep-login-facebook {
  padding: 5px 5px;
  margin-top: 8px;
}

.tags-list ul li {
  font-size: 12px;
  background: white;
  padding: 0 10px;
  border-radius: 11px;
}

.tags-list ul {
  margin-bottom: 0;
}

.dropdown-orders.dropdown button {
  margin-bottom: 0px;
  padding: 5px 20px;
}


span.delete-link {
  height: fit-content;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #a1a3b3;
  margin-top: 10px;
  cursor: pointer;
}

.draft-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
}

.discussion-section {
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  min-height: 720px !important;
}

.toogle-done-drafts {
  border: 1px solid;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}

.toogle-done-drafts span {
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.noteboox.done span {
  text-decoration: line-through;
  color: #a1a3b3;
}


footer.Prodotop-footer {
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  left: 0;
  padding: 15px;
}

.valid-emails-list ul {
  list-style: none;
}

.valid-emails-list ul li {
  padding: 0px 7px;
  font-size: 13px;
  border-radius: 11px;
}


.bottom-documents {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 25px;
  display: grid;
  grid-template-columns: 1fr 250px;
  box-shadow: 0px -4px 15px 0 #0000001f;
}

.order-container {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 15px;
}

.order-navigation a {
  font-size: 15px;
}

.order-navigation a {
  font-size: 15px;
}

.active-tab {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 15px;
}

.panel-explication {
  position: fixed;
  background: #f5f5f7;
  right: 15px;
  top: 15px;
  z-index: 99;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
  border-radius: 10px;
  max-width: 380px;
  box-shadow: 0 0px 12px 0px rgb(0 0 0 / 32%);
}

span.badge-customer {
  background: #ffffffdb;
  border-radius: 15px;
  margin-left: 5px;
  padding: 0px 5px;
  color: #000;
  font-size: 12px;
}

#react-select-2-listbox {
  z-index: 20;
}

.rbc-month-view .rbc-header {
  margin: 15px 5px;
}

.prodotop-link {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  &:hover {
    opacity: 0.8;
  }
}

li.list-item.active {
  border-radius: 5px;
  padding: 5px 0px;
}

.retard-filter {
  width: 45%;
}

.retard-filter-item {
  font-size: 12px;
  margin-bottom: 5px;
  text-align: center;
}

.wrap-table td,
.wrap-table th {
  white-space: inherit !important;
}

.ia-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 100;
  left: 0;
  top: 0;
}

.triangle-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.display-none {
  display: none !important;
}

.date-status {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 15px;
  .progress-bar,
  .progress {
    height: 11px !important;
    font-size: 11px;
    font-weight: 600;
  }
  .progress {
    position: relative;
    top: 11px;
  }
}

.profitability {
  .progress-bar,
  .progress {
    height: 15px !important;
    max-width: 350px;
    font-size: 11px;
    font-weight: 600;
  }
}

.status-progress.progress {
  margin-top: 3px;
}

.team-accepted-steps{
  list-style: none;
  overflow: hidden;
  font-size: 14px;
  margin: 0px;
  padding: 0;
  width: 100%;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 600;
  li {
    float: left;
    width: 20%;
  }
  li span {
    text-decoration: none;
    padding: 0px 2px 0px 2px;
    position: relative;
    display: block;
    float: left;
    width: 100%;
  }
}


.order-steps {
  list-style: none;
  overflow: hidden;
  font-size: 14px;
  margin: 0px;
  padding: 0;
  width: 100%;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 600;


  li {
    float: left;
    width: 25%;
  }

  li span {
    text-decoration: none;
    padding: 0px 15px 0px 55px;
    position: relative;
    display: block;
    float: left;
    width: 100%;
  }

  &.two{
    li {
      float: left;
      width: 50% !important;
      span{
        text-align: center !important;
      }
    }
  }





  li span:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    left: 100%;
    z-index: 2;
  }
  li span:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-bottom: 50px solid transparent;
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }
  li:first-child span {
    padding-left: 10px;
  }
}

.bg-header{
  background-position: 0px 0px;
  background-size: contain;
  width: 330px;
  height: 200px;
  background-repeat: no-repeat;
  display: inline-block;
}
.bg-configuration{
  background-image: url("../../public/images/photos/configuration.jpg");
}
.bg-management{
  background-image: url("../../public/images/photos/management.jpg");
}
.bg-agency{
  background-image: url("../../public/images/photos/agency.jpg");
}

.react-datepicker-wrapper {
  display: block !important;
}

.img-post span {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
}

.validation-ce p {
  text-align: justify;
  font-size: 15px;
}

.loading-effect.blur {
  .rbc-calendar {
    animation: opacity 1.5s infinite;
  }
}

.loading {
  animation: opacity 1.5s infinite;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// .dragStart .post-text {
//   display: none !important;
// }
// .dragStart .abs-bot {
//   display: none !important;
// }
// .dragStart .comments-block {
//   display: none !important;
// }
// .dragStart .img-post img {
//   width: 50px !important;
// }
// .dragStart .post-container {
//   display: grid;
//   gap: 25px;
// }

.post-text {
  height: 90px;
  overflow: auto;
  word-break: break-all;
  &.with-comment {
    margin-bottom: 20px;
  }
  &.without-comment {
    margin-bottom: 60px;
  }
}

.comment-section {
  margin-bottom: 5px;
}
.resume-item {
  width: 30%;
}

.community-card-content {
  width: 100%;
}
.abs-bot {
  position: absolute;
  bottom: 0;
}
.card.shared {
  box-shadow: none !important;
}

.img-post {
  border-radius: 10px;
  padding: 10px 0;

  div:first-child {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 10px;
  }

  img {
    margin: 0 10px;
    border-radius: 5px;
  }
}

hr.separator {
  margin-top: 20px !important;
}
.filter-dashboard-manager {
  grid-template-columns: 1fr auto;
}

.order-date-end-filter {
  display: flex;
  padding: 10px;
  width: 45%;
  height: 70px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .big {
    font-weight: 600;
  }

  .small {
    font-size: 14px;
  }
}

span.reset {
  font-size: 14px;
  font-weight: 400;
}

.myCustomHeight.unauthorized .rbc-time-view::after {
  content: " ";
  width: -webkit-fill-available;
  height: 716px;
  opacity: 0.2;
  position: absolute;
  overflow: hidden;
}

small {
  font-size: 12px;
}

nav.breadcrumb {
  padding: 10px 5px;
  margin-top: 10px;
}
.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  border-radius: 3px 3px 0 0 !important;
}
.note-textarea {
  height: 113px;
}

.calendar-add-todo {
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.devid-input {
  width: 100px;
}

.card-order {
  .card-header {
    border-radius: 20px 20px 0 0 !important;
  }
}
li.breadcrumb-item a {
  text-decoration: underline;
}
.span-btn {
  padding: 4px 15px;
  border-radius: 12px;
  font-weight: 400 !important;
  min-width: 160px;
}

h6.mt-3 small {
  font-size: 12px;
}

.event-action {
  width: 100%;
  button {
    height: 75px;
  }
}
.recharts-wrapper {
  margin-top: 15px;
}

.purple-border.card-order.client {
  border: none;
  width: 395px;
}

.has-notification {
  animation: blink 1.5s infinite;
}

.calendar-step {
  padding: 2px 7px;
  border-radius: 5px;
}

.rbc-event.custom-event {
  .rbc-event-label {
    margin-bottom: 5px;
  }
}

.notification-card {
  height: 445px;
  overflow: auto;
}

.ck.ck-editor__main .ck-content {
  min-height: 200px;
}
.answer-response {
  overflow: hidden;
}

h6.companyName-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card {
  border-radius: 14px !important;
  &.shadow-none{
    box-shadow: none !important;
  }
  .card-body {
    border-radius: 14px !important;
  }
}


.btn.btn-link {
  font-size: 15px;
}
.rbc-event-label {
  display: none !important;
}

a.btn-img img {
  width: 130px;
  margin: auto;
  border-radius: 7px;
}

.purple-border {
  border: 0px solid;
}

.task-calendar-container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
}

.task-cisors::after {
  display: none;
}
.task-name {
  font-size: 14px;
  line-height: 1.3;
}

.notification-active {
  border-radius: 10px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.myCustomHeight {
  min-height: 764px;
  height: calc(100% - 55px);

  .rbc-event {
    display: block !important;
  }
  .rbc-day-slot .rbc-event-content {
    font-size: 14px;
  }
  .rbc-event-label {
    font-size: 14px;
  }
  .rbc-event.custom-event .rbc-event-label {
    margin-bottom: 2px;
  }
  .rbc-event.custom-event:hover::after, .rbc-event-content:hover:after {
    visibility: visible;
  }

  .rbc-event.custom-event:after, .rbc-event-content:after {
    content: attr(title);
    font-size: 14px !important;
    position: fixed !important;
    padding: 8px !important;
    border-radius: 8px !important;
    visibility: hidden;
    width: 230px !important;
    height: 120px !important;
    z-index: 99999 !important;
    margin: 10px !important;
    display: block;
    white-space: pre-wrap;
  }
}



a.btn-img {
  display: grid;
  grid-auto-rows: 1fr auto;
}

h6.table-h6 {
  font-weight: 500;
}

.nav-link {
  cursor: pointer;
}
.card.dashboard-card {
  border-radius: 15px !important;
}

#react-select-9-listbox,
#react-select-4-listbox {
  z-index: 1000 !important;
}
ul.recharts-default-legend {
  text-align: left !important;
  display: grid;
  position: relative;
  top: 30px;
}

.h-220 {
  max-height: 220px;
}
.border-left-3 {
  border-left-width: 1px !important;
  border-left-style: solid;
}
.chart-icon {
  height: 44px !important;
  width: 42px;
  padding: 5px;
  border: 1px solid;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.task-container {
  height: 936px;
  grid-template-rows: 1fr auto;
  max-width: 350px;
  .tasks {
    overflow-y: auto;
  }
  .task-explanation span {
    font-size: 12px;
  }

  span.box-task {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 3px;
  }

  &.show{
    min-width: 350px;
  }
}

.expanded-modal {
  position: fixed;
  padding: 30px;
  min-height: 600px;
  max-width: 1450px;
  width: 1000px;
  border-radius: 20px;
  top: 150px;
  left: 50%;
  transform: translateX(-50%) !important;
  z-index: 9999 !important;
}
.expanded-modal {
  max-height: 707px;
  overflow: auto;
}

.modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

span.small-rect {
  margin-left: 32px;
  width: 20px;
  height: 10px;
  display: block;
  border-radius: 5px;
}

.icon-shape {
  cursor: pointer;
}

.icon-shape {
  width: 30px;
  height: 30px;
  background-position: center;
  border-radius: 13px;
}

.icon-shape i {
  opacity: 0.8;
  top: 2px;
  position: relative;
  font-size: 13px !important;
}

.undesigned{
  .icon-shape {
    width: 15px;
    height: 15px;
  }
  
  .icon-shape i {
    opacity: 0.8;
    top: 2px;
    position: relative;
    font-size: 13px !important;
  }
}

.icon-shape .ni {
  top: 14px;
}

button.close {
  border-radius: 5px;
  float: right;
}
.star {
  display: inline-block;
  width: 16px; /* ou la taille que vous préférez */
  height: 16px; /* doit être égale à la largeur pour une étoile uniforme */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 3px;

  &.full {
    background-image: url("./svg/star-symbol-icon.svg");
  }

  &.half {
    background-image: url("./svg/star-half-icon.svg"); /* Star Half */
  }

  &.empty {
    background-image: url("./svg/star-line-yellow-icon.svg"); /* Star Empty */
  }
}
.accordion-toggle {
  border-bottom: 1px solid;
  font-size: 14px;
  span.badge-accordion {
    min-width: 20px;
    text-align: center;
    border-radius: 3px;
    height: 22.5px;
    font-weight: 600;
  }
}

.product-title, .text-sm{
  font-size: 14px !important;
}

.product-large-card {
  span.product-title {
    position: absolute;
    left: 10px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .product-card-item {
    width: calc(16.6% - 15px);
  }

  .icon-shape {
    width: 30px;
    height: 30px;
    background-position: center;
    border-radius: 1.75rem;
    i {
      top: 0;
    }
  }
}

.chart-icon.productCardView {
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

// calendar

.rbc-toolbar {
  padding: 25px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0px;
}

.rbc-time-view {
  border-radius: 10px;
}

.rbc-header {
  font-size: 15px !important;
  height: 35px;
  padding-top: 5px;
  border: none;
  border-radius: 10px;
  margin-right: 3px;
}

.rbc-label.rbc-time-header-gutter {
  border: none;
}

.rbc-time-header.rbc-overflowing {
  border: none;
}

.rbc-timeslot-group {
  padding: 0px 20px;
}

.rbc-row.rbc-time-header-cell {
  z-index: 1;
}

.rbc-day-bg + .rbc-day-bg {
  border: none;
}

.rbc-header + .rbc-header {
  border: none;
}
.rbc-time-header-content {
  border-left: none;
}

.py-1.custom-gray.tata {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rbc-time-content {
  border: none;
}

span.rbc-toolbar-label {
  font-size: 17px;
}

span.rbc-toolbar-label {
  font-size: 17px;
}

.workers-container h6 {
  margin-bottom: 1px;
}

.rbc-time-header.rbc-overflowing {
  margin-right: 0 !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@for $i from 0 through 50 {
  $rem-value: $i * 0.25;
  .w-#{$i} {
    width: #{$rem-value}rem;
  }
  .h-#{$i} {
    height: #{$rem-value}rem;
  }
}